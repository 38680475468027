import { compress, decompress } from "lz-string";

export function cookieGETUserID() {
  let user_info = JSON.parse(localStorage.getItem("active_till"));
  if (user_info) {
    let publisherId = user_info.id;
    return publisherId;
  }
  return false;
}

export function cookiePOSTLoggedInTill(id) {
  const cookie = {
    id: id,
  };
  localStorage.setItem("active_till", JSON.stringify(cookie));
  window.location.pathname = "/till/main";
}

export function cookiePOSTActive(id) {
  const cookie = {
    id,
  };
  localStorage.setItem("active_till", JSON.stringify(cookie));
}

export function cookiePOSTLoggedInClocking(id) {
  const cookie = {
    id: id,
  };
  localStorage.setItem("active_till", JSON.stringify(cookie));
}

export function cookieGETUserRoleID(id) {
  let user_info = JSON.parse(localStorage.getItem(id + "_acc_info"));
  let roleId = user_info.role;
  return roleId;
}

export function cookieGETUserName(id) {
  let user_info = JSON.parse(localStorage.getItem(id + "_acc_info"));
  let userName = user_info.userFirst + " " + (user_info.userLast || "");
  return userName;
}

export function cookieGETBranchID() {
  let user_info = JSON.parse(localStorage.getItem("branch_info"));
  let branchID = user_info.branchID;
  return branchID;
}

export function cookieGETBranchName() {
  let user_info = JSON.parse(localStorage.getItem("branch_info"));
  let branchName = user_info.branchName;
  return branchName;
}

export function cookiePOSTVerifyToken(history) {
  const tokenStr = localStorage.getItem("user_token");
  if (tokenStr === null) {
    if (history.location.pathname !== "/") {
      history.push({ pathname: "/" });
    }
  } else {
    //TODO - verify token
    const info = JSON.parse(localStorage.getItem("user_info"));
    if (info.expiry === null) {
      if (history.location.pathname !== "/") {
        history.push({ pathname: "/" });
      }
    } else {
      const now = new Date();
      if (now.getTime() > info.expiry) {
        localStorage.removeItem("token");
        if (history.location.pathname !== "/") {
          history.push({ pathname: "/" });
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function cookieGETIfClocked(id) {
  if (localStorage.getItem(id + "_acc_info")) {
    return true;
  } else {
    return false;
  }
}

export function cookiePOSTBranchInfo(branch) {
  const cookie = {
    branchID: branch.ID,
    branchName: branch.name,
  };
  localStorage.setItem("branch_info", JSON.stringify(cookie));
}

export function cookiePOSTUserInfo(user, branchID) {
  let time = new Date();
  const cookie = {
    userFirst: user.firstName,
    userLast: user.lastName,
    id: user.ID,
    branchID,
    role: user.roleID,
    expiry: time.getTime() + 12 * 60 * 60 * 1000,
  };

  localStorage.setItem(user.ID + "_acc_info", JSON.stringify(cookie));
  //window.location.pathname = "/till/main"
}

export function cookieCHECKAllUsers() {
  let time = new Date();
  let keys = Object.keys(localStorage);
  let active = JSON.parse(localStorage.getItem("active_till"));
  if (keys) {
    let users = keys.filter((e) => e.includes("_acc_info"));
    users.map((user) => {
      let user_info = JSON.parse(localStorage.getItem(user));
      if (
        user_info.expiry < time.getTime() ||
        user_info.branchID !== cookieGETBranchID()
      ) {
        if (user_info.ID === active.ID) {
          active = null;
        }
        localStorage.removeItem(user);
      }
    });
    keys = Object.keys(localStorage);
    users = keys.filter((e) => e.includes("_acc_info"));
    if (
      users &&
      users.length > 0 &&
      active &&
      Object.keys(active).length === 0
    ) {
      cookiePOSTActive(users[0].substring(0, users[0].indexOf("_")));
    } else if (users.length === 0) {
      localStorage.removeItem("branch_info");
      localStorage.removeItem("active_till");
      window.location.pathname = "/till/login";
    }
  }
}

export function cookieDELETEUserInfo(id) {
  localStorage.removeItem(id + "_acc_info");
}

export function cookieDELETECloseTill() {
  localStorage.removeItem("branch_info");
  localStorage.removeItem("active_till");
  localStorage.removeItem("till_close");
  window.location.pathname = "/till/login";
}

export function cookieGETAuthenticator() {
  const token = localStorage.getItem("auth_token");
  if (token) {
    return token;
  }
  return false;
}

export function cookieGETAdminID() {
  let admin_info = JSON.parse(localStorage.getItem("admin_info"));
  if (admin_info) {
    let adminID = admin_info.id;
    return adminID;
  }
}

export function cookieGETAdminName() {
  let admin_info = JSON.parse(localStorage.getItem("admin_info"));
  let adminName = admin_info.name;
  return adminName;
}

export function cookieGETAdminArea() {
  let admin_info = JSON.parse(localStorage.getItem("admin_info"));
  let adminArea = admin_info.areaID;
  return adminArea;
}

export function cookieCHECKAuthenticator() {
  const token = JSON.parse(localStorage.getItem("auth_token"));
  if (!token || token.expiry < new Date(Date.now()).valueOf()) {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("admin_info");
    window.location.pathname = "/auth/login";
  }
}

export function cookieDELETEAuthenticator() {
  localStorage.removeItem("auth_token");
  window.location.pathname = "/auth/login";
}

export function cookiePOSTAuthenticator(adminInfo) {
  const cookieTime = 10 * 60 * 60 * 1000; //10 HRS
  const cookie = {
    token: "4o4j3io3124hjr593124ur84u3",
    expiry: new Date(Date.now() + cookieTime).valueOf(),
  };
  const admin_info = {
    id: adminInfo.ID,
    name: adminInfo.firstName,
    areaID: adminInfo.areaID,
  };
  localStorage.setItem("auth_token", JSON.stringify(cookie));
  localStorage.setItem("admin_info", JSON.stringify(admin_info));
  window.location.pathname = "/app";
}

export function cookieSAVEstocktake(stockTake, branchID) {
  if (stockTake.length > 0) {
    const data = JSON.stringify({ stockTake, branchID });
    const compressedData = compress(data);
    localStorage.setItem("stock_take_data", compressedData);
  } else {
    localStorage.removeItem("stock_take_data");
  }
}

export function cookieLOADstocktake() {
  const compressedData = localStorage.getItem("stock_take_data");
  if (compressedData) {
    const data = decompress(compressedData);
    const { stockTake, branchID } = JSON.parse(data);
    return [stockTake, branchID];
  }
  return [null, null];
}

export function cookieDELstocktake() {
  localStorage.removeItem("stock_take_data");
}

export function cookiePOSTclosetill() {
  localStorage.setItem("till_close", JSON.stringify(true));
}

export function cookieGETclosetill() {
  let closeTill = JSON.parse(localStorage.getItem("till_close"));
  return closeTill;
}

export function cookiePOSTlicense(key) {
  localStorage.setItem("license_key", JSON.stringify(key));
}

export function cookieGETlicense() {
  let closeTill = JSON.parse(localStorage.getItem("license_key"));
  return closeTill;
}

export function cookieSETofflineRequests(body, request) {
  let requests = JSON.parse(localStorage.getItem("offline_requests")) || [];
  let datetime = new Date().toISOString().slice(0, 19).replace("T", " ");
  requests.push({ request, body, datetime });
  localStorage.setItem("offline_requests", JSON.stringify(requests));
}

export function cookieGETofflineRequests() {
  let requests = JSON.parse(localStorage.getItem("offline_requests"));
  return requests;
}

export function cookieDELofflineRequests() {
  localStorage.removeItem("offline_requests");
  localStorage.setItem("is_offline_requests", JSON.stringify(false));
}

export function cookieSETofflineTimeout() {
  var now = new Date();
  now.setTime(now.getTime() + 30 * 60 * 1000);
  let body = { expiry: now };
  localStorage.setItem("offline_timeout", JSON.stringify(body));
}

export function cookieGETofflineTimeout() {
  var now = new Date();
  let requests = JSON.parse(localStorage.getItem("offline_timeout"));
  if (requests) {
    var expiry = new Date(requests.expiry);
    if (now >= expiry || !expiry) {
      cookieDELofflineTimeout();
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function cookieDELofflineTimeout() {
  localStorage.removeItem("offline_timeout");
}

export function cookieSETCategories(body) {
  localStorage.setItem("offline_category", JSON.stringify(body));
}

export function cookieSETRefundData_trans(body) {
  localStorage.setItem("offline_refund_trans", JSON.stringify(body));
}

export function cookieSETRefundData_order(body) {
  localStorage.setItem("offline_refund_orders", JSON.stringify(body));
}

export function cookieSETDiscountTypes(body) {
  localStorage.setItem("offline_discount", JSON.stringify(body));
}

export function cookieSETTenderTypes(body) {
  localStorage.setItem("offline_tender", JSON.stringify(body));
}

export function cookieSETClockingData(body) {
  localStorage.setItem("offline_clocking", JSON.stringify(body));
}

export function cookieGETCategories() {
  return JSON.parse(localStorage.getItem("offline_category"));
}

export function cookieGETRefundData_trans() {
  return JSON.parse(localStorage.getItem("offline_refund_trans"));
}

export function cookieGETRefundData_order() {
  return JSON.parse(localStorage.getItem("offline_refund_orders"));
}

export function cookieGETDiscountTypes() {
  return JSON.parse(localStorage.getItem("offline_discount"));
}

export function cookieGETTenderTypes() {
  return JSON.parse(localStorage.getItem("offline_tender"));
}

export function cookieGETClockingData() {
  return JSON.parse(localStorage.getItem("offline_clocking"));
}

export function cookieSETofflineRequestsResync(requests) {
  if (requests == []) {
    localStorage.removeItem("offline_requests");
  } else {
    localStorage.setItem("offline_requests", JSON.stringify(requests));
  }
}

export function cookieTROUBLESHOOT_SET(request) {
  let TROUBLE = JSON.parse(localStorage.getItem("TROUBLE"));
  if (TROUBLE) {
    TROUBLE.push(request);
  } else {
    TROUBLE = [];
  }
  localStorage.setItem("TROUBLE", JSON.stringify(TROUBLE));
}

export function cookieTROUBLESHOOT_GET() {
  return localStorage.getItem("TROUBLE");
}

export function cookieTROUBLESHOOT_REMOVE() {
  let keys = Object.keys(localStorage);
  if (keys.length > 0) {
    keys.map((item) => {
      if (item != "license_key") localStorage.removeItem(item);
    });
  }
}

export function cookiePOSTPurchaseOrderDraft(state) {
  localStorage.setItem("purchase_draft", JSON.stringify(state));
}

export function cookieGETPurchaseOrderDraft() {
  return JSON.parse(localStorage.getItem("purchase_draft"));
}

export function cookieDELETEPurchaseOrderDraft() {
  localStorage.removeItem("purchase_draft");
}
