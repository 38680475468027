//export const API_BASE_URL = 'https://mbepos.com/api/'
//export const API_BASE_URL = 'https://ukbackend.mbepos.com/api/'

//export const API_BASE_URL = 'https://mbitzepos.com/api/api/'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//types
export const API_CATEGORY = "category/";
export const API_DISCOUNT_TYPE = "discountType/";
export const API_NOSALE_TYPE = "nosaleType/";
export const API_PETTY_TYPE = "pettyType/";
export const API_REFUND_TYPE = "refundType/";
export const API_REPAIR_TYPE = "repairType/";
export const API_ROLE_TYPE = "roleType/";
export const API_STOCKMOVE_TYPE = "stockmoveType/";
export const API_TAXRATE = "taxRate/";
export const API_TENDER_TYPE = "tenderType/";

//objects
export const API_ADMIN = "admin/";
export const API_ALLOWED_LOCATION = "allowedLocation/";
export const API_AREA = "area/";
export const API_BOOKING_SYSTEM = "bookingSystem/";
export const API_BRANCH_LICENSE = "branchLicense/";
export const API_EMPLOYEE = "employee/";
export const API_BRANCH = "branch/";
export const API_PRODUCT = "product/";
export const API_PURCHASE = "purchase/";
export const API_REFUND = "refund/";
export const API_REPAIR = "repair/";
export const API_MISC = "misc/";
export const API_ORDER = "order/";
export const API_STOCK = "stock/";
export const API_STOCK_HISTORY = "stockHistory/";
export const API_STOCKORDER = "stockOrder/";
export const API_STOCKORDER_ITEMS = "stockOrderItems/";
export const API_TRANSACTION = "transaction/";
